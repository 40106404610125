<template>
    <section>
      <!--工具条-->
      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
  
      <!--列表-->
      <el-table
        :data="Valueattribute.list"
        v-loading="listLoading"
        @select="dialogCheck"
        @row-click="selectCurrentRow"
        ref="table"
        style="width: 100%"
        class="custom-tbl"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" width="80"></el-table-column>
        <el-table-column
          prop="Name"
          label="名称"
          width
          sortable
        ></el-table-column>  
        <el-table-column prop="Type" label="类型" width sortable>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Type == 1">文本</el-tag> 
          <el-tag v-if="scope.row.Type == 2">整数</el-tag>
          <el-tag v-if="scope.row.Type == 3">数字</el-tag>
          <el-tag v-if="scope.row.Type == 4">下拉列表</el-tag>
          <el-tag v-if="scope.row.Type == 5">单选</el-tag>
          <el-tag v-if="scope.row.Type == 6">多选</el-tag>
          <el-tag v-if="scope.row.Type == 7">日期</el-tag>
        </template>
      </el-table-column>

        <el-table-column prop="Enabled" label="是否为空" width="200" sortable>
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.IsNull ? 'success' : 'danger'"
            disable-transitions
            >{{ scope.row.IsNull ? "允许空值" : "必填项" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
          prop="Code"
          label="编码"
          width
          sortable
        ></el-table-column> 
      </el-table>
  
      <!--工具条-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="50"
          :total="total"
          style="float: right"
        ></el-pagination>
      </el-col>
  
      <!--编辑界面-->
      <el-dialog
        title="编辑"
        :visible.sync="editFormVisible"
        v-model="editFormVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <el-form
          :model="editForm"
          label-width="80px"
          :rules="editFormRules"
          ref="editForm"
        >
          <el-form-item label="名称" prop="Name">
            <el-input
              disabled
              v-model="editForm.Name"
              auto-complete="off"
            ></el-input>
          </el-form-item>  
          <el-form-item label="类型" prop="Type">
          <el-select
            v-model="editForm.Type"
            placeholder="请选择类型"
          > 
            <el-option label="文本" :value="1"></el-option>
            <el-option label="整数" :value="2"></el-option>
            <el-option label="数字" :value="3"></el-option>
            <el-option label="下拉列表" :value="4"></el-option>
            <el-option label="单选" :value="5"></el-option>
            <el-option label="多选" :value="6"></el-option>
            <el-option label="日期" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为空" prop="IsNull" width sortable>
          <el-switch v-model="editForm.IsNull"></el-switch>
        </el-form-item>
        <el-form-item label="编码" prop="Code">
            <el-input 
              v-model="editForm.Code"
              auto-complete="off"
            ></el-input>
          </el-form-item> 
          <el-form-item label="值" prop="Value">
            <el-input 
              v-model="editForm.Value"
              auto-complete="off"
            ></el-input>
          </el-form-item> 
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="editFormVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click.native="editSubmit"
            :loading="editLoading"
            >提交</el-button
          >
        </div>
      </el-dialog>
  
      <!--新增界面-->
      <el-dialog
        title="新增"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <el-form
          :model="addForm"
          label-width="80px"
          :rules="addFormRules"
          ref="addForm"
        >
          <el-form-item label="类型名称" prop="Name">
            <el-input v-model="addForm.Name" auto-complete="off"></el-input>
          </el-form-item> 
          <el-form-item label="类型" prop="Type">
          <el-select
            v-model="addForm.Type"
            placeholder="请选择类型"
          > 
            <el-option label="文本" :value="1"></el-option>
            <el-option label="整数" :value="2"></el-option>
            <el-option label="数字" :value="3"></el-option>
            <el-option label="下拉列表" :value="4"></el-option>
            <el-option label="单选" :value="5"></el-option>
            <el-option label="多选" :value="6"></el-option>
            <el-option label="日期" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为空" prop="IsNull" width sortable>
          <el-switch v-model="addForm.IsNull"></el-switch>
        </el-form-item>
        <el-form-item label="编码" prop="Code">
            <el-input 
              v-model="addForm.Code"
              auto-complete="off"
            ></el-input>
          </el-form-item> 
          <el-form-item label="值" prop="Value">
            <el-input 
              v-model="addForm.Value"
              auto-complete="off"
            ></el-input>
          </el-form-item> 
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addFormVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click.native="addSubmit"
            :loading="addLoading"
            >提交</el-button
          >
        </div>
      </el-dialog>
    </section> 
   
  </template>

 

  <script>
  import util from "../../../util/date";
  
  import {
    getAssetTypeValueListPage2,
    removeAssetTypeValue,
    editAssetTypeValue,
    addAssetTypeValue,
    addSysOperateLog  
  } from "../../apis/apis";
  import { getButtonList } from "../../promissionRouter";
  import Toolbar from "../../components/Toolbar";  
  import routes from "../../router/index";

  export default {
    name:'assettypevalue',
    components: { Toolbar },
    props: ["Valueattribute"] ,
    data() {
      return {
        filters: {
          name: "",
        },
        buttonList: [],
        options: [], 
        defaultProps: {
          children: "children",
          label: "label",
        }, 
        total: this.Valueattribute.count,
        page: 1,
        listLoading: false,
        sels: [], //列表选中列
        currentRow: null,
        addDialogFormVisible: false,
        editFormVisible: false, //编辑界面是否显示
        editLoading: false,
        editFormRules: {
          Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
        },
        //编辑界面数据
        editForm: {
          Id: 0, 
          Name: "",  
          ComputeTypeIds: "",
          ComputeTypeName:[],
          AssetTypeId:0,
          IsNull:0,
          IsDeleted:false,
          Code:""
        },
  
        addFormVisible: false, //新增界面是否显示
        addLoading: false,
        addFormRules: {
          Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
        },
        //新增界面数据
        addForm: {
          Name: "",  
          ComputeTypeIds: "",
          ComputeTypeName:[],
          AssetTypeId:0,
          IsNull:0,
          IsDeleted:false,
          Code:""
        },
        isResouceShow: 0,
        //测算方法列表
        computelist:[],
        drawer:false,
        direction: 'rtl',
        drawername:''
      };
    },
    computed: { 
    },  
    methods: {  
      drawershow(index,row){
        this.drawer = true;
        var m = Object.assign({}, row);
        this.drawername=m.Name; 
      },
      dialogCheck(selection, row) {
        this.currentRow = null;
        this.$refs.table.clearSelection();
        if (selection.length === 0) {
          return;
        }
        if (row) {
          this.selectCurrentRow(row);
        }
      },
      selectCurrentRow(val) {
        if (val) {
          this.currentRow = val;
          this.$refs.table.clearSelection();
          this.$refs.table.toggleRowSelection(val, true);
        }
      },
      callFunction(item) {
        this.filters = {
          name: item.search,
        };
        this[item.Func].apply(this, item);
      },
       
      formatCreateTime: function (row, column) {
        return !row.CreateTime || row.CreateTime == ""
          ? ""
          : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getAssetTypes();
      },
     
      getAssetTypes() { 
        let para = {
          page: this.page,
          key: this.filters.name,
          assetTypeId:this.Valueattribute.tid
        };
        this.listLoading = true;

        getAssetTypeValueListPage2(para).then((res) => {
          this.total = res.data.response.dataCount;
          this.Valueattribute.list = res.data.response.data;
    
          this.listLoading = false;
          
        }); 
      }, 
      //删除
      handleDel() {
        let row = this.currentRow;
        if (!row) {
          this.$message({
            message: "请选择要删除的一行数据！",
            type: "error",
          });
  
          return;
        }
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            let para = { id: row.Id };
            removeAssetTypeValue(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.listLoading = false;
                return;
              }
              this.listLoading = false;
              //NProgress.done();
              if (res.data.success) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
  
              this.getAssetTypes();
                //操作日志
            let param = {  ExternalId: row.Id,Name:routes.history.current.name+"-删除" };
            addSysOperateLog(param);
            });
          })
          .catch(() => {});
      },
      //显示编辑界面
      handleEdit() {
        let row = this.currentRow;
        this.options = [];
        if (!row) {
          this.$message({
            message: "请选择要编辑的一行数据！",
            type: "error",
          });
  
          return;
        }
   
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
        
      },
      //显示新增界面
      handleAdd() {
        this.addFormVisible = true;
        this.options = [];
        this.addForm = { 
          Name: "", 
          ComputeTypeIds: "", 
          AssetTypeId:0,
          IsNull:0,
          IsDeleted:false,
          Code:""
        };
   
      },
      //编辑
      editSubmit: function () {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
              this.editLoading = true;
              //NProgress.start();
              let para = Object.assign({}, this.editForm);
    
              editAssetTypeValue(para).then((res) => {
                if (util.isEmt.format(res)) {
                  this.editLoading = false;
                  return;
                }
  
                if (res.data.success) {
                  this.editLoading = false; 
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getAssetTypes();
                  //操作日志
                 let param = {  ExternalId: para.Id,Name:routes.history.current.name+"-编辑" };
                addSysOperateLog(param);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            });
          }
        });
      },
      //新增
      addSubmit: function () { 
        let _this = this;
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
              this.addLoading = true;
              //NProgress.start();
              let para = Object.assign({}, this.addForm);
             
              var user = JSON.parse(window.localStorage.user);
  
              if (user && user.uID > 0) {
                para.CreateId = user.uID; 
              } else {
                this.$message({
                  message: "用户信息为空，先登录",
                  type: "error",
                });
                _this.$router.replace(
                  _this.$route.query.redirect ? _this.$route.query.redirect : "/"
                );
              }
  
              para.AssetTypeId = this.Valueattribute.tid;
              addAssetTypeValue(para).then((res) => {
                if (util.isEmt.format(res)) {
                  this.addLoading = false;
                  return;
                }
                if (res.data.success) {
                  this.addLoading = false;
                  //NProgress.done();
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getAssetTypes();
                    //操作日志
               let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-新增" };
                addSysOperateLog(param);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            });
          }
        });
      },
      getButtonList2(routers) {
        let _this = this;
        routers.forEach((element) => {
          let path = this.$route.path.toLowerCase();
          if (element.path && element.path.toLowerCase() == path) {
            _this.buttonList = element.children;
            return;
          } else if (element.children) {
            _this.getButtonList(element.children);
          }
        });
      },
    },
    mounted() {    
      let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []; 
      this.buttonList = getButtonList(this.$route.path, routers);
    }, 
  };
  </script>
  
  <style lang="less" scoped>
  .custom-tbl /deep/ .has-gutter .el-checkbox {
    display: none;
  }
  </style>
  